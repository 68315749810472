.container.alert-notification {
  background: white;
  border: 1px solid #ccd0d4;
  padding: 10px;
  color: black;
  margin-bottom: 10px;
  @each $color, $value in $theme-colors {
    &.alert-notification-#{$color} {
      border-left: 5px solid $value;
    }
  }
}

#faq-section-sidebar {
  .section-sidebar-item {
    color: $color-theme;
  }
}

#faq-section-container {
  h3.d-block.mv-20 {
    color: $color-theme;
  }
}

#page-title {
  img {
    max-width: 75vw;
  }
}

.grid {
  &.grid-flex {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    li {
      flex: 0 0 auto;
    }
  }
}
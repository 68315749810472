// Layout 
$layout-boxed-width: 1200px !default;
$layout-modern-width: 1500px !default;
// Typography
$font-base: "Poppins",
sans-serif;
$font-body: $font-base;
$font-headings: $font-base;
$font-paragraph: "Nunito",
sans-serif;
$font-menu: $font-base;
$font-menu-dropdown: $font-base;
//Icons
$font-size-icon: 24px !default;
$font-icon: "inspiro-icons";
$font-icon-fontawesome: "Font Awesome 5 Free";
$font-icon-fontawesome-brands: "Font Awesome 5 Brands";
// Font size
$font-size: 0.924rem !default;
$font-weight: 300 !default;
// Base Colors
$color-master: #626262 !default;
$color-theme: #2250fc !default;
$color-theme-hover: lighter($color-theme, 5%);
$color-body: #484848 !default;
$color-primary: $color-theme;
$color-secondary: #5A6268 !default;
$color-success: #81c868 !default;
$color-info: #53b0f8 !default;
$color-warning: #ffb20e !default;
$color-danger: #CE041C !default;
$color-complete: #2250fc !default;
$color-white: #ffffff !default;
$color-black: #000000 !default;
$color-grey: #cecece !default;
$color-light: #999999 !default;
$color-lighter: lighten($color-light, 1.4%);
$color-lightest: lighten($color-light, 3.5%);
$color-dark: #9896a6 !default;
$color-dark-hover: #e2e2e2 !default;
$color-dark:mix($color-master, #000, 45%) !default;
$color-darker:mix($color-master, #000, 27%) !default;
$color-darkest:mix($color-master, #000, 18%) !default;
$grey: #F7F9FB !default;
$dark: #1f1f1f !default;
$darker: #292929 !default;
$dark-light: #bbbbbb !default;
$light: #eeeeee !default;
$dark-grey: #262626 !default;
$slate-grey: #444444 !default;
$silver: #cccccc !default;
$color-heading: $dark;
$paragraph-color: #777777 !default;
$background-light: #f8f9fa !default;
$background-dark: #181818 !default;
$background-white: #fff !default;
$background-black: #000 !default;
$background-theme: $color-theme;
$background-grey: #F7F9FB !default;
$background-primary: $color-theme;
$background-secondary: $color-secondary;
$background-success: $color-success;
$background-info: $color-info;
$background-warning: $color-warning;
$background-danger: $color-danger;
// Header
$header-height: 80px !default;
$header-line-height: 5.682rem !default;
// Slider
$slider-font: $font-base;
// Side Panel
$side-panel-width: 300px !default;
$side-panel-width-collapsed: 80px !default;
// Page Titile
$page-title-font: $font-base;
$page-title-background: $background-light;
// Sidebar
$sidebar-background: $background-light;
// Footer
$footer-background: $background-light;
// Margins
$default-margin: 14px !default;
// Transitions
$transition-ease: all 0.3s ease !default;
// Utilities
$border-width: 1px !default;
$border-color: #ececec !default;
$border-color-dark: #2b2b2b !default;
$border-radius: 5px !default;
$border-background-color: $color-secondary;
// Buttons
$button-border-radius: $border-radius;
// Forms
$form-background-color: $color-lightest;
$form-border-width: $border-width;
$form-border-color: $border-color;
$form-border-radius: $border-radius;
// Define common padding and border radius sizes and more.
$hover-color: $color-theme;
$active-color: $color-theme;
$active-bg-color: $grey;
$box-shadow: 0 6px 38px rgba(0, 0, 0, 0.05) !default;
$line-height-lg: 1.5 !default;
$line-height-sm: 1.5 !default;
$border-width: 1px !default;
$border-color: $border-color !default;
$border-radius: 5px !default;
$border-radius-lg: 8px !default;
$border-radius-sm: 10px !default;
$transition-base: all .3s ease !default;
$transition-fade: opacity .15s linear !default;
// Layout Breakpoints
$breakpoint-xs: 0px !default; // Extra small devices
$breakpoint-sm: 576px !default; // Small devices (landscape phones, 576px and up)
$breakpoint-md: 768px !default; // Medium devices (tablets, 768px and up)
$breakpoint-lg: 1024px !default; // Large devices (desktops, 992px and up)
$breakpoint-xl: 1200px !default; // Extra large devices (large desktops, 1200px and up)
//Spacing
$spacer : 1.5rem !default;
/* ----------------------------------------------------------------
    Dropcat & Highlight
-----------------------------------------------------------------*/
.dropcap {
    background: none repeat scroll 0 0 $light;
    border-radius: 4px;
    display: inline-block;
    float: left;
    font-size: 1.421rem;
    height: 40px;
    line-height: 2.841rem;
    margin: 8px 20px 0 0;
    text-align: center;
    width: 40px;

    &.dropcap-circle {
        border-radius: 50%;
    }

    &.dropcap-large {
        font-size: 3.125rem;
        height: 80px;
        line-height: 5.682rem;
        width: 80px;
    }

    &.dropcap-small {
        font-size: 1.137rem;
        height: 20px;
        line-height: 1.42rem;
        margin: 3px 5px -3px 0;
        width: 21px;
    }

    &.dropcap-colored {
        background-color: $color-theme;
        color: $color-white;
    }
}

span.highlight {
    padding: 0 5px;
}

.highlight {
    &.highlight-primary {
        color: $color-primary;
        font-weight: bold;
    }

    &.highlight-secondary {
        color: $color-secondary;
        font-weight: bold;
    }

    &.highlight-warning {
        color: $color-warning;
        font-weight: bold;
    }

    &.highlight-danger {
        color: $color-danger;
        font-weight: bold;
    }

    &.highlight-info {
        color: $color-info;
        font-weight: bold;
    }

    a {
        color: #333;
    }

    &.highlight-large {
        font-size: 1.421rem;
    }

    &.highlight-small {
        font-size: 0.853rem;
        padding: 0 7px;
    }

    &.highlight-colored {
        background-color: $color-theme;
        color: $color-white;
    }
}
/* === Import Bootstrap functions and variables === */

@import "../../../../../node_modules/bootstrap/scss/functions";
@import "../../../../../node_modules/bootstrap/scss/variables";
/*-------------------------------------------------------------------*/

/* === Import template variables === */

@import "variables";
@import "../../../../../assets/scss/poloVariables";
/*-------------------------------------------------------------------*/

/* === Boostrap Main SCSS === */

@import "../../../../../node_modules/bootstrap/scss/bootstrap";
/*-------------------------------------------------------------------*/

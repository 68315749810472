/* ----------------------------------------------------------------------
	Headings
-------------------------------------------------------------------------*/
.heading {
    margin-bottom: 100px;
    text-align: center;

    >h1,
    >h2 {
        font-size: 2.841rem;
        text-transform: capitalize;
        line-height: 3.267rem;
        font-weight: 300;
    }

    >h1,
    >h2,
    >h4,
    >p {
        margin-bottom: default-bottom-margin;
    }
}

.heading-text {
    margin-bottom: 30px;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        position: relative;
        margin-bottom: 30px;
    }

    h1 {
        font-size: 4.972rem;
        font-weight: 600;
    }

    h2 {
        font-size: 4.262rem;
        margin-bottom: 30px;
        letter-spacing: -.7px;
        line-height: 4.688rem;
    }

    h3 {
        font-size: 4.262rem;
        margin-bottom: 30px;
        letter-spacing: -.7px;
        line-height: 4.688rem;
    }

    h4 {
        font-size: 2.415rem;
        margin-bottom: 30px;
        letter-spacing: -.7px;
        line-height: 2.841rem;
    }

    p {
        font-size: 1.279rem;
        margin-bottom: 30px;
    }

    &.heading-gradient {
        h2 {
            >* {
                -webkit-text-fill-color: transparent !important;
                -webkit-background-clip: text !important;
                background: radial-gradient(circle at left top, #4b72ff 9%, #2250fc 48%, #6442ff 91%);
            }
        }
    }

    &.heading-section {
        >h2 {
            position: relative;
            font-size: 3.41rem;
            line-height: 3.977rem;
            margin-bottom: 60px;

            &:before {
                content: "";
                position: absolute;
                height: 2px;
                width: 100px;
                background-color: $color-theme;
                bottom: -30px;
                left: 0;
                right: 0;
            }
        }

        &.text-center {

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                &:before {
                    margin: 0 auto;
                }
            }
        }

        &.text-right {

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                &:before {
                    right: 0;
                    left: auto;
                }
            }
        }

        p {
            font-size: 1.279rem;
            font-weight: 300;
            line-height: 1.563rem;

            span {
                font-weight: 600;
            }
        }
    }

    &.heading-plain {
        >h2 {
            font-size: 3.978rem;
            line-height: 3.977rem;
            font-family: initial;
        }

        p {
            font-size: 1.421rem;
            font-weight: 300;
            line-height: 2.131rem;

            span {
                font-weight: 600;
            }
        }
    }

    &.heading-light {
        >h2 {
            font-size: 4.191rem;
            font-weight: 100;
            letter-spacing: -1px;
            line-height: 4.972rem;
        }

        p {
            font-size: 1.208rem;
            font-weight: 300;
            line-height: 2.131rem;
            margin-bottom: 20px;

            span {
                font-weight: 600;
            }
        }
    }

    &.heading-vintage {
        >h2 {
            border: 2px solid $color-white;
            display: inline-block;
            padding: 8px 21px;
            margin-bottom: 30px;
        }

        p {
            font-size: 1.208rem;
            font-weight: 300;
            line-height: 2.131rem;
            margin-bottom: 20px;

            span {
                font-weight: 600;
            }
        }
    }

    &.heading-line {

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            &:before {
                content: '';
                position: absolute;
                width: 30px;
                height: 2px;
                left: 0;
                bottom: -8px;
                right: 0;
                background-color: #ddd;
            }
        }

        &.text-center {

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                &:before {
                    margin: 0 auto;
                }
            }
        }

        &.text-right {

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                &:before {
                    right: 0;
                    left: auto;
                }
            }
        }
    }

    &.heading-small {

        >h2,
        >h3 {
            margin-bottom: 10px;
        }

        p {
            font-size: 1.137rem;
            font-weight: 300;
            line-height: 1.563rem;
            margin-bottom: 20px;
        }
    }
}

.font-xl {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    a,
    span {
        font-size: 7rem;
        font-weight: 800;
        line-height: 1.2;
    }
}

/*Creative fade heading*/
.heading-creative {
    font-size: 4.404rem;
    font-weight: 100;
    position: relative;
    text-align: left;
    width: 100%;

    strong {
        color: $color-theme;
    }
}

/*Fixes*/
#header:not([data-transparent="true"]).dark .heading-creative {
    color: $light;
}
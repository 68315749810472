/* ----------------------------------------------------------------------
	Counters
-------------------------------------------------------------------------*/
// DELETE VARIABLES
// Counters
.counter {
	margin-bottom: $default-margin;

	span {
		font-size: 3.552rem;
		line-height: 2.841rem;
	}

	&.small {
		>span {
			font-size: 2.273rem !important;
		}
	}

	&.medium {
		>span {
			font-size: 3.552rem !important;
		}
	}

	&.large {
		>span {
			font-size: 4.404rem !important;
		}
	}
}

.icon-box {
	&.fancy {
		>.counter span {
			margin: 0 12px 10px 56px;
		}

		&.medium {
			>.counter span {
				margin: 0 12px 10px 80px;
			}
		}
	}

	&.effect {
		&:not(.center) {
			>.counter span {
				margin: 0 12px 10px 82px;
			}
		}

		&.large:not(.center) {
			>.counter span {
				margin: 0 12px 10px 107px;
			}
		}

		&.medium:not(.center) {
			>.counter span {
				margin: 0 12px 10px 82px
			}
		}

		&.small:not(.center) {
			>.counter span {
				margin: 0 12px 10px 58px;
				font-size: 2.273rem !important;
			}
		}
	}
}
// Nav & Navbar
/*
.navbar {
	.form-control {
		height: 34px;
        padding: 6px 12px;
	}
	.btn {
		border-radius: 0;
        padding: 5px 16px 7px;
	}
}*/